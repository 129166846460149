<template>
  <div class="relative">
    <input
      v-model="password"
      :type="showPassword ? 'input' : 'password'"
      class="input-box"
      placeholder=""
      required
    >
    <div class="absolute right-0 top-0 bottom-0 z-10 px-2">
      <div class="flex items-center justify-center pt-1 border-l pl-1">
        <i v-if="showPassword" class="fa fa-eye" style="color: black;" @click="() => showPassword = false" />
        <i v-if="!showPassword" class="fa fa-eye-slash" @click="() => showPassword = true" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{
  modelValue: string
}>()
interface Emits {
  (e: 'update:modelValue', val: string): void
}
const emits = defineEmits<Emits>()

const password = ref(props.modelValue)
const showPassword = ref(false)

watch(password, (current, prev) => {
  if (current !== prev) {
    emits('update:modelValue', current)
  }
})
</script>
